import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import Content from 'components/Content'

function AnalyticsPage({ location, authData, applications }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : ''
	return (
		<Layout location={location} 
				title={pageTitle} 
				authData={authData} 
				applications={applications}
		>
			<Content />
		</Layout>
	)
}
AnalyticsPage.propTypes = {
	location: PropTypes.object,
}
export default AnalyticsPage
